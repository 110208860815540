.login {
  height: 100vh;
  background: var(--primary-gradient),
    url("../../assets/images/login-bg.jpg") no-repeat center center / cover;
  /* background-size: 300%; */
  /* animation: bg-animation 50s infinite alternate; */
}
.backBtn {
  float: right;
  color: var(--primary-color-100);
  padding-top: 10px;
}

.loginText {
  color: rgb(8, 2, 2);
  text-align: center;
  font-size: 2.5rem;
  font-weight: 700;
  letter-spacing: 2px;
  font-family: "Oswald", sans-serif;
  background-image: var(--text-gradient);
  background-clip: text;
  color: transparent;
}

/* @keyframes bg-animation {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
} */
