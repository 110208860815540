.footerButtom {
  height: 100px;
  border: none;
  border-top: 1px solid var(--text-light-100);
}
.col1 {
  padding: 40px 10px 0px 20px;
  /* padding-top: 50px; */
}
.col1 > p {
  color: rgb(150, 154, 154);
}
.col1 > h3 {
  color: white;
}
.col1 > h6 {
  color: white;
}
.maine {
  top: 0;
  left: 10;
  margin-left: 10px;
}
.innerf {
  width: 0;
  height: 0;
  border-bottom: 30px solid rgb(41, 2, 113);
  border-left: 30px solid transparent;
}
.inners {
  background: var(--text-gradient);
  width: 300px;
  height: 60px;
  /* padding: 15px 40px; */
  text-align: center;
  padding-top: 7px;
}
h6 {
  color: var(--transparent-white);
}
h3 {
  color: var(--white);
}
.box {
  width: 100px;
  height: 50px;
  /* background: var(--primary-gradient); */
  margin-left: 5px;
  border: 1px solid var(--text-light-300);
  color: aliceblue;
  align-self: center;
  text-align: center;
  padding-top: 10px;
}
