#top-nav {
  /* height: 100px; */
  position: sticky;
  z-index: 1000;
  top: 0;
  border-bottom: solid 2px var(--light);
}

.top-nav-content {
  /* height: 50px; */
  margin: 0 30px;
  margin-top: 15px;
}

#notification-icon {
  height: 50px;
  width: 50px;
  border-radius: 10px;
  background-color: var(--primary-blue);
}
.number{
  background: rgb(212, 3, 3);
  width: 13px;
  height: 13px;
  border-radius: 50%;
  position: absolute;
  right: 10px;
  top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.not-text{
  font-size: 10px;
  line-height: 15px;
  color: wheat;

}
.bg-lighter{
  background: rgb(247, 248, 251) !important;
}
.drop-menu{
  position: absolute;
  right: -10px;
}
#user-image {
  width: 50px;
  height: 50px;
}
.font-size {
  font-size: 1.8rem;
  margin-inline: 10px;
  
  
}
@media (max-width: 767px) {
  #top-nav {
    height: auto;
  }

  #notification-icon {
    height: 30px;
    width: 30px;
    border-radius: 10px;
    background-color: var(--primary-blue);
  }
    .number {
      background: rgb(212, 3, 3);
      width: 10px;
      height: 10px;
      border-radius: 50%;
      position: absolute;
      right: 5px;
      top: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .not-text {
      font-size: 8px;
      line-height: 15px;
      color: wheat;
  
    }

  #user-image {
    width: 30px;
    height: 30px;
  }

  .top-nav-content {
    height: auto;
    margin: 0 10px;
  }
}

.topbar-divider {
  margin: 10px 0px;
  width: 100px;
  border: 1px solid #f4f4f4;
}

.token-signal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px 10px;
  height: 18px;
  left: 67px;
  top: 0px;
  border-radius: 20px;
  line-height: 12px;
}

.token-active {
  background: #6fcf97;
}

.token-inactive {
  background: #eb5757;
}
