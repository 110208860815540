.login{
    height: 100vh;
    background: var(--primary-gradient),
    url('../../assets/images/login-bg.jpg') no-repeat center center /cover;
    /* background-size: 300%; */
    /* animation: bg-animation 50s infinite alternate; */
}

/* @keyframes bg-animation {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
} */




