.wrapper {
  height: 70vh;
  background: var(--primary-gradient),
    url("../../assets/images/login-bg.jpg") no-repeat center center / cover;
  background-size: 200%;
  animation: bg-animation 80s infinite alternate;
}

@keyframes bg-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.getStarted {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.findRoomyImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
